import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css"; 
import './scss/index.scss';
import client from '../src/api/apolloClient';
import { ApolloProvider } from '@apollo/client';
import { fetchCsrfToken } from '../src/api/csrf';
import { DataProvider } from '../src/context/DataContext'; 

const Root = () => {
  useEffect(() => {
    fetchCsrfToken().then(csrfToken => {
      document.cookie = `csrftoken=${csrfToken}; path=/`;
    });
  }, []);

  return (
    <ApolloProvider client={client}>
      <DataProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </DataProvider>
    </ApolloProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);