import React from "react";
import Spacing from "../../Spacing";

const ChangePassword = () => {
  return (
    <>
      <Spacing lg="70" md="0" />
      <div style={{ padding: "20px 40px" }}>
        <div
          className="row"
          style={{
            // border: "1px solid rgb(255 255 255 / 34%)",
            background: "#151515",
            borderRadius: "6px",
            padding: "10px 30px",
          }}
        >
          <div style={{ textAlign: "center" }} >
            <h4 style={{ fontFamily: "sans-serif", margin: "15px 0"  }}>
              Change Password
            </h4>
          </div>
          <div className="col-md-12">
            <div className="ProfileFormContainer" style={{ color: "white" }}>
              <label htmlFor="oldpassword" className="mb-1 mt-4">
                Old Password
              </label>
              <input
                type="password"
                placeholder="Enter old password"
                class="cs-form_field "
                style={{ borderRadius: ".25rem" }}
              />
              <br />
              <label htmlFor="newpassword" className="mb-1">
                New Password
              </label>
              <input
                type="password"
                placeholder="Enter new password"
                class="cs-form_field "
                style={{ borderRadius: ".25rem" }}
              />
              <br />
              <label htmlFor="confirmnewpassword" className="mb-1">
                Confirm Password
              </label>
              <input
                type="text"
                placeholder="Confirm new password"
                class="cs-form_field "
                style={{ borderRadius: ".25rem" }}
              />
              <br />
              <button
                style={{ width: "100%", borderRadius: ".25rem" }}
                className="cs-btn cs-style1 mb-4"
              >
                <span>Update</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
