import React from "react";
import { Table } from "react-bootstrap";
import Spacing from "../../Spacing";

const BillingHistory = () => {
  return (
    <>
      <Spacing lg="70" md="0" />
      <div style={{ padding: "20px 40px" }}>
        <div
          className="row"
          style={{
            // border: "1px solid rgb(255 255 255 / 34%)",
            background: "#151515",
            borderRadius: "6px",
            overflow: "hidden"
          }}
        >
          <div style={{ 
              // borderBottom: "1px solid rgb(255 255 255 / 34%)", 
              padding: "20px", 
              background: "#212529" 
            }}>
            <h4 style={{ fontFamily: "sans-serif", margin: "0 0" }}>
              Billing History
            </h4>
          </div>
          <div className="col-md-12" style={{ background: "#212529" }}>
            <Table className="mt-3" striped bordered hover responsive variant="dark">
              <thead>
                <tr style={{ borderTop: "none" }}>
                  <th>Invoice ID</th>
                  <th>Status</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>6WCQRXDXC8PXXIUO</td>
                  <td>Upcoming Charge</td>
                  <td>09/24/2024</td>
                  <td>$216</td>
                  <td style={{ display: "flex", }}>
                    <button
                      style={{ width: "150px", height: "40px", borderRadius: ".5rem" }}
                      className="cs-btn cs-style1"
                    >
                      <span>View Details</span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>6WCQRXDXC8PXXIUO</td>
                  <td>Upcoming Charge</td>
                  <td>09/24/2024</td>
                  <td>$216</td>
                  <td style={{ display: "flex", }}>
                    <button
                      style={{ width: "150px", height: "40px", borderRadius: ".5rem" }}
                      className="cs-btn cs-style1"
                    >
                      <span>View Details</span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>6WCQRXDXC8PXXIUO</td>
                  <td>Upcoming Charge</td>
                  <td>09/24/2024</td>
                  <td>$216</td>
                  <td style={{ display: "flex", }}>
                    <button
                      style={{ width: "150px", height: "40px", borderRadius: ".5rem" }}
                      className="cs-btn cs-style1"
                    >
                      <span>View Details</span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>6WCQRXDXC8PXXIUO</td>
                  <td>Upcoming Charge</td>
                  <td>09/24/2024</td>
                  <td>$216</td>
                  <td style={{ display: "flex", }}>
                    <button
                      style={{ width: "150px", height: "40px", borderRadius: ".5rem" }}
                      className="cs-btn cs-style1"
                    >
                      <span>View Details</span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>6WCQRXDXC8PXXIUO</td>
                  <td>Upcoming Charge</td>
                  <td>09/24/2024</td>
                  <td>$216</td>
                  <td style={{ display: "flex", }}>
                    <button
                      style={{ width: "150px", height: "40px", borderRadius: ".5rem" }}
                      className="cs-btn cs-style1"
                    >
                      <span>View Details</span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>6WCQRXDXC8PXXIUO</td>
                  <td>Upcoming Charge</td>
                  <td>09/24/2024</td>
                  <td>$216</td>
                  <td style={{ display: "flex", }}>
                    <button
                      style={{ width: "150px", height: "40px", borderRadius: ".5rem" }}
                      className="cs-btn cs-style1"
                    >
                      <span>View Details</span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>6WCQRXDXC8PXXIUO</td>
                  <td>Upcoming Charge</td>
                  <td>09/24/2024</td>
                  <td>$216</td>
                  <td style={{ display: "flex", }}>
                    <button
                      style={{ width: "150px", height: "40px", borderRadius: ".5rem" }}
                      className="cs-btn cs-style1"
                    >
                      <span>View Details</span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>6WCQRXDXC8PXXIUO</td>
                  <td>Upcoming Charge</td>
                  <td>09/24/2024</td>
                  <td>$216</td>
                  <td style={{ display: "flex", }}>
                    <button
                      style={{ width: "150px", height: "40px", borderRadius: ".5rem" }}
                      className="cs-btn cs-style1"
                    >
                      <span>View Details</span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>6WCQRXDXC8PXXIUO</td>
                  <td>Upcoming Charge</td>
                  <td>09/24/2024</td>
                  <td>$216</td>
                  <td style={{ display: "flex", }}>
                    <button
                      style={{ width: "150px", height: "40px", borderRadius: ".5rem" }}
                      className="cs-btn cs-style1"
                    >
                      <span>View Details</span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>6WCQRXDXC8PXXIUO</td>
                  <td>Upcoming Charge</td>
                  <td>09/24/2024</td>
                  <td>$216</td>
                  <td style={{ display: "flex", }}>
                    <button
                      style={{ width: "150px", height: "40px", borderRadius: ".5rem" }}
                      className="cs-btn cs-style1"
                    >
                      <span>View Details</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingHistory;
