import React from "react";
import Spacing from "../../Spacing";

const Profile = () => {
  return (
    <>
      <Spacing lg="70" md="0" />
      <div className="ProfileContainer">
        <h4 style={{ fontFamily: "sans-serif", margin: " 0 0 10px 0", textAlign: "center" }}>
          Profile Setting
        </h4>
        <div className="row">
          <div className="col-md-12 mt-4">
            <div className="row">
            <div className="row d-flex ProfileImageContainer mb-4" style={{ paddingRight: "0" }}>
              <div className="col-md-6 d-flex ProfileImage" style={{ justifyContent: "end" }}>
                <div style={{ height: "150px", width: "150px", borderRadius: "10000px", overflow: "hidden", boxShadow: "#FF4A17 0px 0px 10px 2px", }}>
                  <img
                      src="/images/meCV2.png"
                      alt="Profile Image"
                      style={{ height: "100%", width: "100%", objectFit: "cover", }}
                    />
                </div>
              </div>
              <div className="col-md-6 ProfileBtnContainer">
                <button className="cs-btn cs-style1 mt-2 ProfileBtn">
                  <span>Change Picture</span>
                </button>
                <button style={{ background: "#fff", color: "#000" }} className="cs-btn cs-style1 mt-2 ProfileBtn">
                  <span>Delete Picture</span>
                </button>
              </div>
            </div>
            </div>
            <div className="ProfileFormContainer" style={{ color: "white" }}>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="FirstName" className="mb-1" >First Name</label>
                  <input type="text" placeholder="Enter First Name" class="cs-form_field " style={{ borderRadius: ".25rem" }} />
                </div>
                <div className="col-md-6">
                  <label htmlFor="LastName" className="mb-1" >Last Name</label>
                  <input type="text" placeholder="Enter Last Name" class="cs-form_field " style={{ borderRadius: ".25rem" }} />
                </div>
              </div>
              <br />
              <label htmlFor="Username" className="mb-1" >Username</label>
              <input type="text" placeholder="Enter Username" class="cs-form_field " style={{ borderRadius: ".25rem" }} />
              <br />
              <label htmlFor="email" className="mb-1" >Email</label>
              <input type="email" placeholder="Enter email" class="cs-form_field " style={{ borderRadius: ".25rem" }} />
              <br />
              <label htmlFor="address" className="mb-1" >Address</label>
              <textarea type="text" placeholder="Enter your address" class="cs-form_field " style={{ borderRadius: ".25rem" }} />
              <br />
              <button style={{ width: "100%", borderRadius: ".25rem" }} className="cs-btn cs-style1 mb-4">
                <span>Update</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
