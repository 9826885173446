import axios from 'axios';

let baseURL = '/graphql';

if (process.env.REACT_APP_MODE === 'production') {
    baseURL = process.env.REACT_APP_DEV;
} else {
    baseURL = process.env.REACT_APP_PROD;
}
export { baseURL };

console.log('baseURL', baseURL);

const axiosInstance = axios.create({
    baseURL: baseURL + 'graphql',
});

export default axiosInstance;