// DataTable.js
import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Tooltip } from "primereact/tooltip";
import Spacing from "../../Spacing";
import Div from "../../Div";
import Table from "react-bootstrap/Table";
import { FaInfoCircle } from "react-icons/fa";

const DataTable = ({ configs, colors }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabChange = (e) => {
    setActiveTabIndex(e.index);
  };

  return (
    <>
      <Spacing lg="20" />
      <TabView activeIndex={activeTabIndex} onTabChange={handleTabChange}>
        {Object.entries(configs).map(([key, { titleText, data }], index) => {
          if (!data || data.length === 0)
            return (
              <TabPanel key={key}>
                <Div className="container">
                  <p>No data available</p>
                </Div>
              </TabPanel>
            );

          // Extract headers dynamically from the data
          let headers = Object.keys(data[0]).filter(
            (key) => key !== "__typename"
          );

          // Check if 'x-overall' should be included
          const includeXOverall = data.some(
            (row) => row["x-overall"] !== undefined
          );

          if (includeXOverall && !headers.includes("x-overall")) {
            headers.push("x-overall");
          }

          return (
            <TabPanel key={key}>
              <Div
                className="container custom-scrollbar"
                style={{ overflowX: "scroll", overflowY: "hidden" }}
              >
                <div>
                  <div className="text-centered">
                  {/* Info Icon with Tooltip */}
                  <FaInfoCircle
                    id={`info-icon-${index}`}
                    style={{
                      fontSize: "24px",
                      color: "#FF4A17",
                      cursor: "pointer",
                      marginBottom: "10px",
                    }}
                  />
                  <Tooltip
                    target={`#info-icon-${index}`}
                    content={titleText}
                    position="right"
                    className="custom-tooltip"
                  />
                  {/* End of Info Icon with Tooltip */}
                  </div>

                  <Table striped bordered hover variant="dark">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        {headers.map((header, idx) => (
                          <th key={idx}>{header}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        .filter((row) => {
                          // Count the number of empty columns in the row
                          const emptyCount = headers.reduce(
                            (count, header) => {
                              const value = row[header];
                              if (
                                value === "" ||
                                value === null ||
                                value === undefined
                              ) {
                                return count + 1;
                              }
                              return count;
                            },
                            0
                          );

                          // Keep the row if it has less than 3 empty columns
                          return emptyCount < 3;
                        })
                        .map((row, rowIndex) => {
                          // Determine the color for this row
                          let color;
                          if (colors && colors.length > 0) {
                            const segmentSize = Math.ceil(
                              data.length / colors.length
                            );
                            const colorIndex = Math.floor(
                              rowIndex / segmentSize
                            );
                            color =
                              colors[colorIndex] || colors[colors.length - 1];
                          } else {
                            color = undefined; // default color
                          }

                          return (
                            <tr key={rowIndex}>
                              <td>{rowIndex + 1}</td>
                              {headers.map((header, valueIndex) => (
                                <td key={valueIndex} style={{ color: color }}>
                                  {row[header]}
                                </td>
                              ))}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </Div>
            </TabPanel>
          );
        })}
      </TabView>
    </>
  );
};

export default DataTable;
