import React from "react";
import Spacing from "../../Spacing";
import "./ManageSubscription.scss";
import CreditCard from "./CreditCard";
import { FaMapMarkerAlt, FaPen } from "react-icons/fa";
import { ProgressBar, Table } from "react-bootstrap";

const ManageSubscription = () => {
  return (
    <>
      <Spacing lg="100" md="80" />
      <div className="container" style={{ padding: "0" }}>
        <div style={{ padding: "20px 40px" }}>
          <div
            className="row"
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <div className="col-md-4 p-0 col-sm-12 CreditCardContainer">
              <CreditCard />
            </div>
            <div className="col-md-8 ManageSubscriptionRightContainer">
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <h2 className="AvailableDollarSetting">$23.00</h2>
                <p className="AvailableDollarSetting" style={{ fontSize: "25px", marginLeft: "10px" }}> Available</p>
              </div>
                <ProgressBar>
                  <ProgressBar striped variant="danger" now={50} key={1} />
                </ProgressBar>
              <div
                style={{
                  border: "1px solid rgb(255 255 255 / 34%)",
                  background: "#212529",
                  borderRadius: "6px",
                  width: "100%",
                  // height: "100%",
                  padding: "10px 25px",
                }}
                className="AddressBox mt-2"
              >
                <div className="row mt-3">
                  <div
                    className="col-md-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 className="col-md-6 AvailableDollarSetting">Billing Address</h4>
                    <button
                      style={{
                        width: "40px",
                        height: "40px",
                        padding: "0",
                        background: "white",
                        borderRadius: ".25rem",
                      }}
                      className="col-md-6 cs-btn cs-style1"
                    >
                      <FaPen color="black" style={{ margin: "0" }} />
                    </button>
                  </div>
                </div>
                <div className="row mt-3">
                  <div
                    className="col-md-12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 className="col-md-1">
                      <FaMapMarkerAlt />
                    </h4>
                    <p className="col-md-11 AddressText">
                      123 Main Street, San Francisco, CA-9417, United States
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row mt-4"
            style={{
              border: "1px solid rgb(255 255 255 / 34%)",
              background: "#151515",
              borderRadius: "6px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid rgb(255 255 255 / 34%)",
                padding: "20px",
                background: "#212529",
              }}
            >
              <h4 style={{ fontFamily: "sans-serif", margin: "0 0" }}>
                Subscription Log
              </h4>
            </div>
            <div className="col-md-12" style={{ background: "#212529" }}>
              <Table
                className="mt-3"
                striped
                bordered
                hover
                responsive
                variant="dark"
              >
                <thead>
                  <tr style={{ borderTop: "none" }}>
                    <th>Invoice ID</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>6WCQRXDXC8PXXIUO</td>
                    <td>Upcoming Charge</td>
                    <td>09/24/2024</td>
                    <td>$216</td>
                    <td style={{ display: "flex" }}>
                      <button
                        style={{
                          width: "150px",
                          height: "40px",
                          borderRadius: ".5rem",
                        }}
                        className="cs-btn cs-style1"
                      >
                        <span>View Details</span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>6WCQRXDXC8PXXIUO</td>
                    <td>Upcoming Charge</td>
                    <td>09/24/2024</td>
                    <td>$216</td>
                    <td style={{ display: "flex" }}>
                      <button
                        style={{
                          width: "150px",
                          height: "40px",
                          borderRadius: ".5rem",
                        }}
                        className="cs-btn cs-style1"
                      >
                        <span>View Details</span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>6WCQRXDXC8PXXIUO</td>
                    <td>Upcoming Charge</td>
                    <td>09/24/2024</td>
                    <td>$216</td>
                    <td style={{ display: "flex" }}>
                      <button
                        style={{
                          width: "150px",
                          height: "40px",
                          borderRadius: ".5rem",
                        }}
                        className="cs-btn cs-style1"
                      >
                        <span>View Details</span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>6WCQRXDXC8PXXIUO</td>
                    <td>Upcoming Charge</td>
                    <td>09/24/2024</td>
                    <td>$216</td>
                    <td style={{ display: "flex" }}>
                      <button
                        style={{
                          width: "150px",
                          height: "40px",
                          borderRadius: ".5rem",
                        }}
                        className="cs-btn cs-style1"
                      >
                        <span>View Details</span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>6WCQRXDXC8PXXIUO</td>
                    <td>Upcoming Charge</td>
                    <td>09/24/2024</td>
                    <td>$216</td>
                    <td style={{ display: "flex" }}>
                      <button
                        style={{
                          width: "150px",
                          height: "40px",
                          borderRadius: ".5rem",
                        }}
                        className="cs-btn cs-style1"
                      >
                        <span>View Details</span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>6WCQRXDXC8PXXIUO</td>
                    <td>Upcoming Charge</td>
                    <td>09/24/2024</td>
                    <td>$216</td>
                    <td style={{ display: "flex" }}>
                      <button
                        style={{
                          width: "150px",
                          height: "40px",
                          borderRadius: ".5rem",
                        }}
                        className="cs-btn cs-style1"
                      >
                        <span>View Details</span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>6WCQRXDXC8PXXIUO</td>
                    <td>Upcoming Charge</td>
                    <td>09/24/2024</td>
                    <td>$216</td>
                    <td style={{ display: "flex" }}>
                      <button
                        style={{
                          width: "150px",
                          height: "40px",
                          borderRadius: ".5rem",
                        }}
                        className="cs-btn cs-style1"
                      >
                        <span>View Details</span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>6WCQRXDXC8PXXIUO</td>
                    <td>Upcoming Charge</td>
                    <td>09/24/2024</td>
                    <td>$216</td>
                    <td style={{ display: "flex" }}>
                      <button
                        style={{
                          width: "150px",
                          height: "40px",
                          borderRadius: ".5rem",
                        }}
                        className="cs-btn cs-style1"
                      >
                        <span>View Details</span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>6WCQRXDXC8PXXIUO</td>
                    <td>Upcoming Charge</td>
                    <td>09/24/2024</td>
                    <td>$216</td>
                    <td style={{ display: "flex" }}>
                      <button
                        style={{
                          width: "150px",
                          height: "40px",
                          borderRadius: ".5rem",
                        }}
                        className="cs-btn cs-style1"
                      >
                        <span>View Details</span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>6WCQRXDXC8PXXIUO</td>
                    <td>Upcoming Charge</td>
                    <td>09/24/2024</td>
                    <td>$216</td>
                    <td style={{ display: "flex" }}>
                      <button
                        style={{
                          width: "150px",
                          height: "40px",
                          borderRadius: ".5rem",
                        }}
                        className="cs-btn cs-style1"
                      >
                        <span>View Details</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageSubscription;
